import { Component, Input, ViewChild } from '@angular/core';
import { ToastComponent } from '@engie-group/fluid-design-system-angular';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastViewerComponent {
    @ViewChild(ToastComponent, {read: ToastComponent}) toast: ToastComponent;

    @Input()
    Title: string = "";

    @Input()
    Message: string = ""
}
