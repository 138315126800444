import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertsService } from './services/alerts.service';
import { AlertLevel, AlertMessage } from './Dtos/alert.entity';

// This interceptor is used to keep track of how many calls to the API are being executed and push updates through the alert service so loading bars are displayed or hidden
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private _apiCallsCount: number = 0;
  constructor(private _alertService: AlertsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is made to the API endpoint
    if (request.url.includes(environment.apiUrl)) {
      // Increase count and notify 
      this._apiCallsCount++;
      this._alertService.APICallCountUpdate(this._apiCallsCount);

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Decrease count and notify 
            this._apiCallsCount--;
            this._alertService.APICallCountUpdate(this._apiCallsCount);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          // Decrease count and notify 
          this._apiCallsCount--;
          this._alertService.APICallCountUpdate(this._apiCallsCount);
          let message: AlertMessage;
          debugger;
          if (error.status == 400)
          {
            let errorsFound: string = "";
            if (typeof error.error === "string")
              errorsFound = error.error;
            else{
              Object.keys(error.error?.errors).forEach(property =>
                errorsFound += error.error?.errors[property].join("\n")
                );
              }
            message = new AlertMessage(AlertLevel.Error, errorsFound);
          }
          else if(error.status == 551)
            message = new AlertMessage(AlertLevel.Error, error.error);
          else if(error.status == 403 || error.status == 401)
            message = new AlertMessage(AlertLevel.Error, "Your user doesn't have permissions for the requested action or session has expired");
          else if(error.status == 404)
            message = new AlertMessage(AlertLevel.Error, "API endpoint not found. Please contact IS support");
          else
            message = new AlertMessage(AlertLevel.Error, 'Error from ' + request.url + ' url:' + error.message);

          this._alertService.ShowAlert(message);

          // Logic to handle errors from API endpoint
          console.error('Error from ' + request.url + ' url:', error);
          return throwError(() => error);
        })
      );
    }
    else
      return next.handle(request);
  }
}
