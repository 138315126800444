import { Component } from '@angular/core';
import { AlertsService } from './core/services/alerts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pad-portal';
  constructor(private _alertService: AlertsService)
  {
    // _alertService.onAlertReceived.subscribe(m => alert(m.Message));
  }
}
