import { EventEmitter, Injectable, Output } from '@angular/core';
import { AlertMessage } from '../Dtos/alert.entity';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  @Output()
  public onAlertReceived: EventEmitter<AlertMessage> = new EventEmitter<AlertMessage>();
  
  @Output()
  public onAPICallCountChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  public ShowAlert(alert: AlertMessage) {
    this.onAlertReceived.next(alert);
  }

  public APICallCountUpdate(callsCount: number)
  {
    this.onAPICallCountChange.next(callsCount);
  }
}
