import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvailabilityOutage } from 'src/app/core/Dtos/Remit';
import { AlertLevel, AlertMessage } from 'src/app/core/Dtos/alert.entity';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { RemitsService } from 'src/app/core/services/remits.service';
import { StaticDataService } from 'src/app/core/services/static-data.service';

@Component({
  selector: 'app-outage-cancellation',
  templateUrl: './outage-cancellation.component.html',
  styleUrl: './outage-cancellation.component.scss'
})
export class OutageCancellationComponent {
  private _outageId: number = 0;
  public Outage: AvailabilityOutage = new AvailabilityOutage();
  public IsTritonOrMorayUser: boolean = false; // Triton users don't enter published data/method
  public IsSaving: boolean = false;
  public NotificationMethodsList: string[] = [];
  public NotifiersList: string[] = [];

  @Input()
  public set OutageId(value: number) {
    this._outageId = value;
    if (this.OutageId != 0) {
      this.IsSaving = true;
      this.remitsService.GetOutage(this.OutageId).subscribe((data: AvailabilityOutage) => {
        this.Outage = data;
        // this._originalUnavailabilityCode = data.code;
        // this.IsGenerationREMIT = ((data.remitTypeToGenerate & 1) == 1);
        // this.IsConsumptionREMIT = ((data.remitTypeToGenerate & 2) == 2);
        // this.BMU_Changed();
        // this.UpdateReasonAndNotifiers ();
        if (this.Outage.bmu.startsWith("MOWEO-")
          || this.Outage.bmu.startsWith("MOWWO-")
          || this.Outage.bmu.startsWith("INDQ-")
          || this.Outage.bmu.startsWith("SCCL-"))
          this.IsTritonOrMorayUser = true;

        if (this.IsTritonOrMorayUser == true) {
          this.Outage.notificationDateTime = this.DateFormatHelper.ToDateTimeString(new Date());
          this.Outage.notificationMethod = "Submitted By Asset";
          this.Outage.notifiedBy = this.globalState.userName.toLowerCase();
        }
        else
          this.Outage.notificationDateTime = null;

        this.staticDataService.GetNotifiers(this.Outage.bmu).subscribe(data => {
          this.NotifiersList = data;
          // search for the notifier, the name can be in a different case than what is stored in PAD so it's converted to lower case to match.
          let notifier = data.find(n => n.toLowerCase() == this.globalState.userName.toLowerCase());
          if (notifier != null)
            this.Outage.notifiedBy = notifier;
          if (this.IsTritonOrMorayUser == true && this.Outage.remitTypeToGenerate == 0)
              this.SaveAvailability();

          this.IsSaving = false;
        });
      });
    }
  };
  public get OutageId(): number {
    return this._outageId;
  }

  @Output()
  public Close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private remitsService: RemitsService,
    private staticDataService: StaticDataService,
    private globalState: AuthStateService,
    private _alertService: AlertsService,
    public activatedRoute: ActivatedRoute,
    public DateFormatHelper: DateFormatHelperService
  ) {

    // this. IsTritonUser = globalState.groups.indexOf("pad-triton-users") != -1;

    if (this.IsTritonOrMorayUser)
      this.NotificationMethodsList = ["Submitted By Asset"];
    else
      this.staticDataService.GetNotificationsMethods().subscribe(data => this.NotificationMethodsList = data);

  }

  public SaveAvailability() {
    let sErr: string = "";

    if (sErr != "") {
      this._alertService.ShowAlert(new AlertMessage(AlertLevel.Error, sErr));
      return;
    }

    this.IsSaving = true;
    if (this.IsTritonOrMorayUser == true)
      this.Outage.notificationDateTime = this.DateFormatHelper.ToDateTimeString(new Date());

    if (window.confirm("Are you sure you want to cancel the selected outage?") == true) {
      this.remitsService.CancelOutage(this._outageId, this.Outage).subscribe(r => {
        this.Close?.next(true);
        this.IsSaving = false;
      });
    }
    else
      this.IsSaving = false;
  }

  public BackToRemits() {
    this.Close?.next(false);
  }

  public IsMissingData(): boolean {
    let isMissingData: boolean = false;
    if (this.Outage.notifiedBy == null
      || this.Outage.notificationMethod == null
      || this.DateFormatHelper.IsValidDate((this.Outage.notificationDateTime ?? "").toString().trim()) == false)
      isMissingData = true;

    return isMissingData;
  }
}
