<nav class="nj-navbar nj-navbar--shadow nj-navbar--expand-xl mb-3">
  <div class="container-fluid">
    <a class="nj-navbar__brand" href="#"><img src="assets/logo-engie-blue.svg" class="nj-navbar__logo"></a>
    <div class="font-weight-bold" [style]="'font-size: 1.25em;'">Outages, Availability and Inflexes <span
        [style]="'font-weight: bold; color: red;'">{{ExtraInfoHeader}}</span></div>
    <!-- button class="nj-navbar__toggler" type="button" data-toggle="collapse" data-target="#navbarExample04">
        <i class="nj-navbar__toggler-icon material-icons">menu</i>
      </button>
      <div class="nj-navbar--collapse nj-collapse" id="navbarExample04">
        <ul class="nj-navbar__nav ml-auto">
          <li class="nj-navbar__nav-item active"><a class="nj-navbar__nav-link" href="#">Nav link</a></li>
          <li class="nj-navbar__nav-item"><a class="nj-navbar__nav-link" href="#">Nav link</a></li>
          <li class="nj-navbar__nav-item"><a class="nj-navbar__nav-link" href="#">Nav link</a></li>
        </ul>
      </div -->
    <div id="userInfo">
      <div [style]="'float: left;'">
        <button [style]="'border: 0px;'" type="button" (click)="LogOff()">
          <i class="nj-navbar__toggler-icon material-icons">power_settings_new</i>
        </button>
      </div>
      <span>{{UserName}}</span>
    </div>
    <!-- nj-icon
        name="power_settings_new"
        size="md"
        ariaLabel="Log off"
      ></nj-icon -->
  </div>
</nav>
<div [style]="'padding: 16px;font-family: var(--nj-semantic-font-family-default); background:#2b3d83'"
  [class]="DisplayLoading ? '' : 'hide'">
  <nj-spinner [variant]="'inverse'" [size]="'xxs'" [isLoading]="true"></nj-spinner>
  <div [style]="'color: white; float: left'">Loading ...&nbsp;&nbsp;&nbsp;</div>
</div>
<!--
<div class="container-fluid">
    <div class="row">
        <div class="col-md-2"><img src="assets/logo-engie-blue.svg" class="nj-navbar__logo"></div>
        <div class="col-md-10 font-weight-bold nj-lead">Outages, Availability and Inflexes</div>
    </div>
</div>
-->