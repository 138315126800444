import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { AlertLevel, AlertMessage } from '../core/Dtos/alert.entity';
import { AlertsService } from '../core/services/alerts.service';

export class BaseComponent {
    private  _dateFormatHelper: DateFormatHelperService;

    constructor(
        private _alertService: AlertsService,
        dateFormatHelper: DateFormatHelperService)
    {
        this._dateFormatHelper = dateFormatHelper;
    }

    public ExportDataToCSV(data: object[], fileName: string ) {
        if (data?.length == 0)
        {
            this._alertService.ShowAlert(new AlertMessage(AlertLevel.Warning, "No data to export"));
            return;
        }
        // Extract the keys from the first object to use as column headers
        const headers = Object.keys(data[0]);

        // Create an array to store the CSV rows
        const rows = [];

        // Add the headers to the CSV rows
        rows.push(headers.join(','));

        // Iterate over the data and convert each object to a CSV row
        for (const object of data) {
            const values = headers.map(header => this.escapeCell((object as any)[header]));
            rows.push(values.join(','));
        }

        // Create a CSV string from the rows
        const csv = rows.join('\r\n');

        // Create a download link element
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', fileName == '' ? 'export.csv' : fileName);

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
    }
    
    private escapeCell(cell: any) {
        if (cell instanceof Date)
            return this._dateFormatHelper.GetDisplayDate(cell);
        else if (typeof cell === 'string') {
            // If the cell contains a comma, double-quote it and escape existing double quotes
            if (cell.includes(',')) {
            return '"' + cell.replace(/"/g, '""') + '"';
            }
            return cell;
        }
        return String(cell);
    }    
}