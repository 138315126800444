export enum AvailabilityOutageCodes {
    OU = "OU",
    GE = "GE",
    EQ = "EQ",
    LE = "LE"
}

export class AvailabilityOutageNew {
    public fromDate: Date;
    public toDate: Date;
    public bmu: string;
    public availabilityType: string;
    public code: AvailabilityOutageCodes | string = AvailabilityOutageCodes.OU;
    public availabilityValue: number | null = 0;
    public reasonSummary: string;
    public cause: string;
    public sendCauseInETR: boolean;
    public internalNotes: string;
    public durationUncertainty: string;
    public sendDurationInETR: boolean;
    public notificationMethod: string;
}

export class AvailabilityOutageNewReview extends AvailabilityOutageNew {
    public id: number = 0;
}

export class AvailabilityOutage extends AvailabilityOutageNewReview {
    public notifiedBy: string;
    public notificationDateTime: Date | string | null;
    public remitTypeToGenerate: number;
    public relatedInformationCons: string | null;
    public relatedInformationGen: string | null
    public remitTypesGenerated: number; 
}

export class AvailabilityOutageMinDTO {
    public id: number;
    public eventStartTime: Date;
    public eventEndTime: Date;
    public eventStartTimeLocal: Date;
    public eventEndTimeLocal: Date;
    public affectedUnit: string;
    public unavailabilityType: string;
    public unavailabilityCode: string;
    public unavailabilityValue: number;
    public reasonForUnavailability: string;
    public internalNotes: string;
    public durationUncertainty: string;
    public notifiedOn: Date;
    public notifiedBy: string;
    public auditDate: Date;
    public remitsSubmitted: boolean;
}

export class AvailabilityOutageFullMinDTO extends AvailabilityOutageMinDTO {
    public revision: number;
}