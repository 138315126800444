<div class="container-fluid">
    <div class="row">
        Outage:
    </div>
    <div class="row">
        <div class="col-md-2 bold">ID</div>
        <div class="col-md-3">{{ OutageId }}</div>
    </div>
    <div class="row">
        <div class="col-md-2 bold">BMU</div>
        <div class="col-md-3">{{ Outage.bmu }}</div>
    </div>
    <div class="row">
        <div class="col-md-2 bold">Event Start</div>
        <div class="col-md-3">{{ DateFormatHelper.GetDisplayDate(Outage.fromDate)}}</div>
    </div>
    <div class="row">
        <div class="col-md-2 bold">Event End</div>
        <div class="col-md-3">{{ DateFormatHelper.GetDisplayDate(Outage.toDate)}}</div>
    </div>
    <div class="row">&nbsp;</div>
    @if (IsTritonOrMorayUser == false) {
    <div class="row">
        Confirm Outage cancellation by filling in the following information:
    </div>
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="EventNotifiedBy">
                <select njFormField id="EventNotifiedBy" [(ngModel)]="Outage.notifiedBy">
                    <option *ngFor="let notifier of NotifiersList" value='{{notifier}}'>
                        {{notifier}}</option>
                </select>
                <ng-container njFormLabel>Notified By</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="" inputId="EventStartTime" [isRequired]="true">
                <input type="datetime-local" id="EventStartTime" njFormField
                    [(ngModel)]="Outage.notificationDateTime" />
                <ng-container njFormLabel>Notification Date</ng-container>
                <ng-container njFormSubscript></ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true"
                inputId="EventNotificationMethod" [isRequired]="true">
                <select njFormField id="EventNotificationMethod" [(ngModel)]="Outage.notificationMethod">
                    <option *ngFor="let notificationMethod of NotificationMethodsList" value='{{notificationMethod}}'>
                        {{notificationMethod}}</option>
                </select>
                <ng-container njFormLabel>Notification Method</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    }

    @if (Outage.remitTypeToGenerate == 1 || Outage.remitTypeToGenerate == 3) {
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="RelatedInformationGen">
                <textarea id="RelatedInformationGen" [(ngModel)]="Outage.relatedInformationGen" njFormField
                    maxlength="1000"></textarea>
                <ng-container njFormLabel>GENERATION REMIT - Related Information</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    }
    @if (Outage.remitTypeToGenerate == 2 || Outage.remitTypeToGenerate == 3) {
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="RelatedInformationCons">
                <textarea id="RelatedInformationCons" [(ngModel)]="Outage.relatedInformationCons" njFormField
                    maxlength="1000"></textarea>
                <ng-container njFormLabel>CONSUMPTION REMIT - Related Information</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    }
    <div class="row" [style]="'margin-top: 10px;'">
        <div class="col-md-2">
            <nj-button size="normal" type="button" [isDisabled]="IsMissingData() || IsSaving" icon=""
                [hasCustomIcon]="false" (buttonClick)="SaveAvailability()">
                Cancel Outage
            </nj-button>
        </div>
        <div class="col-md-3">
            <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
                (buttonClick)="BackToRemits()">
                Cancel
            </nj-button>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>