export enum AlertLevel {
    Information = "Information",
    Warning = "Warning",
    Error = "Error"
}

export class AlertMessage {
    public Type: AlertLevel;
    public Message: string;

    constructor(type: AlertLevel, message: string) {
        this.Type = type;
        this.Message = message;
    }
}