import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatHelperService {

  constructor() { }
  public GetDisplayDate(date: Date | string) {
    return (new Date(date)).toLocaleDateString('en-GB') + " " + (new Date(date)).toLocaleTimeString();
  }

  public ToDateTimeString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }
  
  public IsValidDate(date : string): boolean{
    let d;
    try {
      d = new Date(date);
    }
    catch {}
    return d instanceof Date && !isNaN(d.getDate());
  }

}
