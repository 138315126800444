import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailabilityOutageMinDTO } from 'src/app/core/Dtos/Remit';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';

@Component({
  selector: 'app-outage-overlap',
  templateUrl: './outage-overlap.component.html',
  styleUrls: ['./outage-overlap.component.scss']
})
export class OutageOverlapComponent {
  @Input()
  public OverlapOutagesData:AvailabilityOutageMinDTO[] = [];
  
  @Output()
  public IsAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public DateFormatHelper: DateFormatHelperService)
  {
  }

  public ResolveIsAccepted(isAccepted: boolean) {
    this.IsAccepted.next(isAccepted);
  }
}
