<p class="font-weight-bold nj-lead">Unit Availability Revisions</p>
<div class="container-fluid">
    <div class="row">
        <div [style]="'padding: 16px'" [style.background]="'transparent'">
            <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false" (buttonClick)="BackToRemits()">
                Back to search results
            </nj-button>
        </div>
        <div [style]="'padding: 16px'" [style.background]="'transparent'">
            <nj-button size="normal" type="button" [isDisabled]="CanCreateRevision == false || IsRemitReporter == false" icon="" [hasCustomIcon]="false" (buttonClick)="CreateNewRevision()">
                Create New Revision
            </nj-button>
        </div>
        @if (FailedToSubmitREMIT == true) {
        <div [style]="'padding: 16px'" [style.background]="'transparent'" [style.color]="'red'">
            This remit was not submitted to Elexon. Please contact GEMS IS support 02071180662
        </div>
        }
    </div>
    <div class="row">
        <div class="col-md-12">All times displayed are in BST</div>
    </div>
    <div class="row">
        <table id="tblRemits" class="nj-table nj-table--hover nj-table--striped nj-cursor">
            <thead>
                <tr [style]="'background-color: #d8d8d8'">
                    <th (click)="SortData('revision')">Revision Number</th>
                    <th (click)="SortData('eventStartTime')">Event Start Time</th>
                    <th (click)="SortData('eventEndTime')">Event End Time</th>
                    <th (click)="SortData('affectedUnit')">Affected Unit</th>
                    <th (click)="SortData('unavailabilityType')">Unavailability Type</th>
                    <th (click)="SortData('unavailabilityValue')">Code / Value</th>
                    <th (click)="SortData('reasonForUnavailability')">Cause</th>
                    <th (click)="SortData('internalNotes')">Internal Notes</th>
                    <th (click)="SortData('durationUncertainty')">Duration Uncertainty</th>
                    <th (click)="SortData('notifiedOn')">Notified On</th>
                    <th (click)="SortData('notifiedBy')">Notified By</th>
                    <th (click)="SortData('auditDate')">Last Publish Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let remit of currentRemitRevisions">
                    <td>{{remit.revision}}</td>
                    <td>{{DateFormatHelper.GetDisplayDate(remit.eventStartTime)}}</td>
                    <td>{{DateFormatHelper.GetDisplayDate(remit.eventEndTime)}}</td>
                    <td>{{remit.affectedUnit}}</td>
                    <td>{{remit.unavailabilityType.toLowerCase() == "maintenance" ? "Planned" : 
                        remit.unavailabilityType.toLowerCase() == "forced" ? "Unplanned" : remit.unavailabilityType }}</td>
                    <td>{{remit.unavailabilityCode}}<br/>{{remit.unavailabilityValue}}</td>
                    <td>{{remit.reasonForUnavailability}}</td>
                    <td>{{remit.internalNotes}}</td>
                    <td>{{remit.durationUncertainty}}</td>
                    <td>{{DateFormatHelper.GetDisplayDate(remit.notifiedOn)}}</td>
                    <td>{{remit.notifiedBy}}</td>
                    <td>{{DateFormatHelper.GetDisplayDate(remit.auditDate)}}</td>
                </tr>
            </tbody>
        </table>
<!--
        <nj-pagination></nj-pagination>
        -->
    </div>
</div>