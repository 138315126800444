import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Filters_GetAvailability } from 'src/app/core/Dtos/Filters.entity';
import { AvailabilityOutageMinDTO } from 'src/app/core/Dtos/Remit';
import { AlertLevel, AlertMessage } from 'src/app/core/Dtos/alert.entity';
import { ListItemData } from 'src/app/core/Dtos/list-item-data';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { RemitsService } from 'src/app/core/services/remits.service';
import { StaticDataService } from 'src/app/core/services/static-data.service';
import { BaseComponent } from 'src/app/features/base-component'
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-outages-list',
  templateUrl: './outages-list.component.html',
  styleUrls: ['./outages-list.component.scss']
})
export class OutagesListComponent extends BaseComponent{
  public BMUList: string[] = []
  /* 
  ["DEEP-1", "DINO-1", "DINO-2", "DINO-3", "DINO-4", "DINO-5", "DINO-6",
    "FFES-1", "FFES-2", "FFES-3", "FFES-4", "IFD-FHCE1", "IFG-FHCE1", "INDQ-1", "KILBW-1", "MILWW-1",
    "MOWEO-1", "MOWEO-2", "MOWEO-3", "RUGGT-6", "RUGGT-7", "RUGPS-6", "RUGPS-7", "SCCL-1", "SCCL-2", "SCCL-3"];
  */
  public AvailabilityTypesList: ListItemData[] = [];
  public SearchDateOcurrence: string = this.toDateString(new Date()); //(new Date()).toISOString().substring(0, (new Date()).toISOString().length - 8);
  public SearchBMUs: string = "ALL";
  public SearchByDate: string = "EventOcurrence";
  public SearchAvailabilityType: string = "ALL";
  public currentOutagesData: AvailabilityOutageMinDTO[] = [];
  public LastSearch: string = "";
  public PagesCount: number = 0;
  public IsRemitReporter: boolean = false;

  private outagesDataSortBy: string = "";
  private outagesDataSortOrder: string = "ASC";
  private pageNumber: number = 0;
  public currentOutagePageData: AvailabilityOutageMinDTO[] = [];
  public OutageIdToRemove: number = 0;
  public UserCanResubmitREMIT: boolean = false;

  constructor(
    private alertService: AlertsService,
    private remitsService: RemitsService,
    private staticDataService: StaticDataService,
    private router: Router,
    private globalState: AuthStateService,
    public DateFormatHelper: DateFormatHelperService) {
    
    super(alertService, DateFormatHelper);
    this.IsRemitReporter = globalState.groups.indexOf(environment.remitReporterGroup) != -1;
    this.UserCanResubmitREMIT = globalState.groups.indexOf(environment.remitResubmitGroup) != -1;


    this.staticDataService.GetBMUs().subscribe(data =>
      this.BMUList = data);
    this.staticDataService.GetAvailabilityTypes().subscribe(data =>
      this.AvailabilityTypesList = data);

    if (localStorage.getItem("loadPrevResults") === "Y")
    {
      this.currentOutagesData = JSON.parse(localStorage.getItem("currentOutagesData") ?? "[]");
      this.PagesCount = Math.ceil(this.currentOutagesData.length / 50);
      this.OutagesShowPage(parseInt(localStorage.getItem("PageNumber") ?? "1"));
      localStorage.setItem("loadPrevResults", "N");
      this.LastSearch = localStorage.getItem("lastSearch") ?? "";
      localStorage.setItem("loadPrevResults", "N");
    }
    else {
      localStorage.removeItem("currentOutagesData");
      this.LastSearch = "";
    }
  }

  public OutageDelete_Closed(saved: boolean)
  {
    this.OutageIdToRemove = 0;
    if (saved == true)
    {
      this.GetAvailability();
    }
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2));
//       + 'T' + date.toTimeString().slice(0,5);
  }

  public GetAvailability() {
    let filters: Filters_GetAvailability = new Filters_GetAvailability();

    if (this.SearchByDate == "EventOcurrence")
      filters.outageActiveOn = new Date(this.SearchDateOcurrence);

    if (this.SearchBMUs == "ALL")
      filters.bmUs = this.BMUList;
    else
      filters.bmUs.push(this.SearchBMUs);

    if (this.SearchAvailabilityType == "ALL")
      filters.availabilityTypes = []; // this.AvailabilityTypesList.map(a => a.Id.toString());
    else
      filters.availabilityTypes.push(this.SearchAvailabilityType);

    this.remitsService.GetAvailability(filters).subscribe(resp => {
      this.currentOutagesData = resp;

      this.outagesDataSortOrder = this.outagesDataSortOrder == "ASC" ? "DESC" : "ASC"; // change current sort order to get it right on the render
      this.PagesCount = Math.ceil(resp.length / 50);
      this.OutagesShowPage(1);
      this.LastSearch = this.DateFormatHelper.GetDisplayDate(new Date())
      localStorage.setItem("filters", JSON.stringify(filters));
      localStorage.setItem("currentOutagesData", JSON.stringify(resp));
      localStorage.setItem("lastSearch", this.LastSearch);
      localStorage.setItem("loadPrevResults", "N");
    });
  }

  public Resubmit(outageId: number)
  {
    if (window.confirm("Are you sure you want to re-submit the REMIT for the selected outage?"))
      this.remitsService
        .ReSubmitRemit(outageId)
        .subscribe( r => {
          this.alertService.ShowAlert(new AlertMessage(AlertLevel.Information, "Remit submitted successfully"));
          this.GetAvailability();
        });
  }

  public GetRemitRevisions(outageId: number) {
    this.router.navigate(['revisions', outageId]);
  }

  public CancelRemit(outageId: number) {
    this.OutageIdToRemove = outageId;

    // if (window.confirm("Are you sure you want to cancel the selected outage?") == true)
    // {
    //   this.remitsService.CancelOutage(outageId).subscribe(r => this.GetAvailability());
    // }
  }

  public CanCancel(endDateTime: Date): boolean
  {
    return (new Date(endDateTime) > new Date());
  }
  
  public CreateNew() {
    this.router.navigate(['outages-new', 0]);
  }

  public ExportToExcel()
  {
    this.ExportDataToCSV(this.currentOutagesData, 'outages.csv');
  }

  public SortData(sortColumn: string) {
    if (this.currentOutagesData == null)
      return;

    if (this.outagesDataSortBy == sortColumn)
      this.outagesDataSortOrder = this.outagesDataSortOrder == "ASC" ? "DESC" : "ASC";
    else
      this.outagesDataSortOrder = "ASC";

    this.outagesDataSortBy = sortColumn;
    if (this.outagesDataSortBy != "")
      this.currentOutagesData.sort(
      (a: any, b: any) => {
        if ((a[this.outagesDataSortBy] ?? '') < (b[this.outagesDataSortBy] ?? '')) {
          return (this.outagesDataSortOrder == "ASC" ? -1 : 1);
        }
        if ((a[this.outagesDataSortBy] ?? '') > (b[this.outagesDataSortBy] ?? '')) {
          return (this.outagesDataSortOrder == "DESC" ? -1 : 1);
        }
        return 0;
      });
      this.OutagesShowPage(this.pageNumber);
  }

  public OutagesShowPage(pageNumber: number)
  {
    const pageSize: number = 50;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    localStorage.setItem("PageNumber", pageNumber.toString());
    this.pageNumber = pageNumber;
    this.currentOutagePageData = this.currentOutagesData.slice(startIndex, endIndex);
  }

  public SetSearchDate(target: any) {
    // this.SearchDateOcurrence = target.valueAsDate;
  }
}
