import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailabilityOutage, AvailabilityOutageCodes, AvailabilityOutageMinDTO, AvailabilityOutageNew } from 'src/app/core/Dtos/Remit';
import { AlertLevel, AlertMessage } from 'src/app/core/Dtos/alert.entity';
import { BMUExtendedInfo } from 'src/app/core/Dtos/bmu-extended-Info.entity';
import { ListItemData } from 'src/app/core/Dtos/list-item-data';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { RemitsService } from 'src/app/core/services/remits.service';
import { StaticDataService } from 'src/app/core/services/static-data.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'app-outages-new',
  templateUrl: './outages-new.component.html',
  styleUrls: ['./outages-new.component.scss']
})
export class OutagesNewComponent extends BaseComponent {
  public BMUList: BMUExtendedInfo[] = [];
  public AvailabilityTypesList: ListItemData[] = [];
  public ReasonSummariesList: string[] = [];
  public NotificationMethodsList: string[] = [];
  public NotifiersList: string[] = [];
  public IsGenerationREMIT: boolean = true;
  public IsConsumptionREMIT: boolean = true;
  public IsTritonOrMorayUnit: boolean = false;
  public IsTritonUser: boolean = false; // Triton users don't enter published data/method
  public UserCanResubmitREMIT: boolean = false;
  public IsFHC: boolean = false;
  public outage: AvailabilityOutage = new AvailabilityOutage();
  public OverlappingOutages: AvailabilityOutageMinDTO[] = [];
  public ShowOverlappingOutages: boolean = false;
  public EventAvailabilityCodes: string[] = ["OU", "LE", "GE"];
  public EnableConsumptionREMIT = environment.enableConsumptionREMIT;
  public IsSaving: boolean = false;
  public ActiveBMU: BMUExtendedInfo | null = null;

  private _outageId: number;
  private _originalUnavailabilityCode: AvailabilityOutageCodes | string | null = null;
  private _saveAndCopyToNew: boolean = false;
  private _submitRemit: boolean;

  constructor(private remitsService: RemitsService,
    private staticDataService: StaticDataService,
    private router: Router,
    private globalState: AuthStateService,
    private alertService: AlertsService,
    public activatedRoute: ActivatedRoute,
    public DateFormatHelper: DateFormatHelperService
  ) {
    super(alertService, DateFormatHelper);
    this._outageId = this.activatedRoute.snapshot.params['outageId'];
    this.IsTritonOrMorayUnit = false;
    this.IsTritonUser = globalState.groups.indexOf(environment.tritonUsersGroup) != -1;
    this.UserCanResubmitREMIT = globalState.groups.indexOf(environment.remitResubmitGroup) != -1;

    // I need to await the BMUs data
    this.staticDataService.GetBMUExtendedInfos().subscribe(data => {
      this.BMUList = data;

      // Verify if the users available units are only triton or moray
      if (data.every(item => /^(MOWEO|MOWWO|INDQ|SCCL)/.test(item.unit)))
        this.IsTritonOrMorayUnit = true;

      this.staticDataService.GetAvailabilityTypes().subscribe(data => this.AvailabilityTypesList = data);
      if (this.IsTritonUser)
        this.NotificationMethodsList = ["Submitted By Asset"];
      else
        this.staticDataService.GetNotificationsMethods().subscribe(data => this.NotificationMethodsList = data);

      if (this._outageId != 0) {
        remitsService.GetOutage(this._outageId).subscribe(data => {
          this.outage = data;

          // If it's flagged as OU, but is only GEN/CON remit, change code to the correct one (previously it was updated for SIC)
          // Reverted per Jess Request 13/01/25
          /*
          if (data.code == AvailabilityOutageCodes.OU && data.remitTypeToGenerate == 1)
            this.outage.code = AvailabilityOutageCodes.LE;
          else if (data.code == AvailabilityOutageCodes.OU && data.remitTypeToGenerate == 2)
            this.outage.code = AvailabilityOutageCodes.GE;
  */

          this._originalUnavailabilityCode = this.outage.code;
          this.IsGenerationREMIT = ((data.remitTypeToGenerate & 1) == 1);
          this.IsConsumptionREMIT = ((data.remitTypeToGenerate & 2) == 2);
          this.BMU_Changed();
          this.UpdateReasonAndNotifiers();
          if (this.IsTritonOrMorayUnit == true) {
            this.outage.notificationDateTime = this.ToDateTimeString(new Date());
            this.outage.notificationMethod = "Submitted By Asset";
          }
          else
            this.outage.notificationDateTime = null;

        });
      }
      else {
        this.outage = new AvailabilityOutage();
        this.outage.sendCauseInETR = true;
        this.outage.sendDurationInETR = true;

        if (this.IsTritonUser) {
          this.outage.notificationDateTime = this.ToDateTimeString(new Date());
          this.outage.notificationMethod = "Submitted By Asset";
        }
      }
    });
  }

  public BMU_Changed() {
    // Triton and Moray East only produce GENERATION remits and always OU
    if (this.outage.bmu.startsWith("MOWEO-")
      || this.outage.bmu.startsWith("MOWWO-")
      || this.outage.bmu.startsWith("INDQ-")
      || this.outage.bmu.startsWith("SCCL-")) {

      this.IsTritonOrMorayUnit = true;
      this.IsFHC = false;

      this.IsGenerationREMIT = true;
      this.IsConsumptionREMIT = false;
      this.outage.sendCauseInETR = true;
      this.outage.sendDurationInETR = true;

      if (this.NotificationMethodsList.indexOf("Submitted By Asset") == -1)
        this.NotificationMethodsList.push("Submitted By Asset");
      this.outage.notificationDateTime = this.ToDateTimeString(new Date());
      this.outage.notificationMethod = "Submitted By Asset";
      this.outage.notifiedBy = this.globalState.userName.toLowerCase();

      this.outage.code = AvailabilityOutageCodes.LE;
/*
      if (this.outage.bmu.startsWith("MOWEO-")
        || this.outage.bmu.startsWith("MOWWO-")) {
        this.outage.code = AvailabilityOutageCodes.LE;
      }
      else
        this.outage.code = AvailabilityOutageCodes.OU;
*/
    }
    else {
      this.IsTritonOrMorayUnit = false;
      this.IsFHC = (this.outage.bmu.startsWith("FFES-") || this.outage.bmu.startsWith("DINO-"));

      const ix = this.NotificationMethodsList.indexOf("Submitted By Asset")
      if (ix != -1)
        this.NotificationMethodsList.splice(ix, 1);

      // FHC doesn't have the outage code EQ
      let code = this.outage.code == "EQ" ? AvailabilityOutageCodes.OU : this.outage.code;
      this.EventAvailabilityCodes = ["OU", "LE", "GE"];
      this.outage.code = code;
    }

    this.ActiveBMU = this.BMUList.find(bmu => this.outage.bmu === bmu.unit) ?? null;

    this.ValidateVolume();
    this.UpdateReasonAndNotifiers();
  }

  public UpdateReasonAndNotifiers() {
    this.staticDataService.GetReasons(this.outage.bmu).subscribe(data => {
      this.ReasonSummariesList = data;
      if ((this.outage.reasonSummary?.trim() ?? "") == "" && data.length > 0)
        this.outage.reasonSummary = data[0];
    });
    this.staticDataService.GetNotifiers(this.outage.bmu).subscribe(data => {
      this.NotifiersList = data;
      // search for the notifier, the name can be in a different case than what is stored in PAD so it's converted to lower case to match.
      let notifier = data.find(n => n.toLowerCase() == this.globalState.userName.toLowerCase());
      if (notifier != null)
        this.outage.notifiedBy = notifier;

    });
  }

  public SaveAvailability(copyToNew: boolean, submitRemit: boolean) {
    let sErr: string = "";
    debugger;
    if (new Date(this.outage.toDate) <= new Date(this.outage.fromDate))
      sErr += "End time must be after Start time!";
    if (new Date(this.outage.toDate) < new Date())
      sErr += "End time can't be in the past!";

    if (this.outage.availabilityValue == null)
      sErr += "Must specify the volume";

    if (sErr != "") {
      this.alertService.ShowAlert(new AlertMessage(AlertLevel.Error, sErr));
      return;
    }

    this.outage.remitTypeToGenerate = (this.IsGenerationREMIT ? 1 : 0) + (this.IsConsumptionREMIT && this.EnableConsumptionREMIT ? 2 : 0);

    // If it's GEN/CON unavailability, but the value available is 0, then flag it as OU so it's correctly displayed in SIC
    /* // Reverted per Jess request on 13/01/25
        if (this.outage.code != AvailabilityOutageCodes.OU && this.outage.availabilityValue == 0)
          this.outage.code = AvailabilityOutageCodes.OU;
    */

    // If the user set the outage to not generate REMIT, confirm it's the expected behaviour
    if (this.outage.remitTypeToGenerate == 0
      && window.confirm("This submission will NOT create a REMIT. Is it correct? If not please tick the checkbox next to 'Generation REMIT' and re-submit.") == false)
      return;

    this._submitRemit = submitRemit;
    this.remitsService.ValidateOverlaps(this.outage).subscribe(
      resp => {
        if (resp.length > 0) {
          this.OverlappingOutages = resp;
          this._saveAndCopyToNew = copyToNew;
          this.ShowOverlappingOutages = true;
        }
        else {
          this._saveAndCopyToNew = copyToNew;
          this.SaveOutage();
        }
      }
    )
  }

  public OverlappingDialogResult(isAccepted: boolean) {
    this.ShowOverlappingOutages = false;
    if (isAccepted == true)
      this.SaveOutage();
    else
      this._saveAndCopyToNew = false;
  }

  private SaveOutage() {
    this.IsSaving = true;
    if (this.IsTritonUser == true)
      this.outage.notificationDateTime = this.ToDateTimeString(new Date());
    if (this._submitRemit == false)
      this.outage.remitTypesGenerated = -1;
    else
      this.outage.remitTypesGenerated = 0;

    this.remitsService.CreateOutage(this.outage).subscribe(
      resp => {
        this.alertService.ShowAlert(new AlertMessage(AlertLevel.Information, "Outage saved successfully"));
        if (this._saveAndCopyToNew == false)
          this.router.navigate(['/']);
        else {
          this.outage.bmu = "";
          if (this.outage.code == AvailabilityOutageCodes.OU && this.outage.remitTypeToGenerate == 1)
            this.outage.code = AvailabilityOutageCodes.LE;
          else if (this.outage.code == AvailabilityOutageCodes.OU && this.outage.remitTypeToGenerate == 2)
            this.outage.code = AvailabilityOutageCodes.GE;
          this.IsSaving = false;
          this._saveAndCopyToNew = false;
          this._submitRemit = true;
        }
      },
      err => {
        // it's a handled error. return to list
        if (err.status == 551)
          this.router.navigate(['/']);

        this.IsSaving = false
        this._saveAndCopyToNew = false;
        this._submitRemit = true;
      }
    )
  }

  private ToDateTimeString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  public BackToRemits() {
    if (window.confirm('Are you sure you want to cancel?')) {
      localStorage.setItem("loadPrevResults", "Y");
      this.router.navigate(['/']);
    }
  }

  public ValidateGenerateRemit(): boolean {
    if (this.outage.availabilityType == null || this.outage.code == null)
      return false;
    else
      return ((this.outage.availabilityType != 'Forced' && this.outage.availabilityType != 'Maintenance') || this.outage.code?.toString() == "GE" ? true : false);
  }

  public SetStartDate(value: any) {
    this.outage.fromDate = value.valueAsDate;
  }

  public SetEndDate(value: any) {
    this.outage.toDate = value.valueAsDate;
  }

  public ValidateReason() {
    if (this.outage.reasonSummary != 'x Free Text x')
      this.outage.cause = '';
  }

  public AvailabilityCode_Changed() {
    if (this._originalUnavailabilityCode != null && this._originalUnavailabilityCode != this.outage.code &&
      ((this.outage.code !== AvailabilityOutageCodes.OU && this._originalUnavailabilityCode == "OU")
        || (this.outage.code !== AvailabilityOutageCodes.OU && this._originalUnavailabilityCode !== "OU"))) {
      this.alertService.ShowAlert(new AlertMessage(AlertLevel.Error, "Changing the Availability Code would produce a different type of REMIT which is not allowed. Please cancel this outage and create a new outage to change the REMIT type"));
      setTimeout(() => {
        this.outage.code = this._originalUnavailabilityCode?.toString() ?? "OU";
      })
      return;
    }

    if (this.outage.code == AvailabilityOutageCodes.OU) {
      this.outage.availabilityValue = 0;
      this.IsConsumptionREMIT = true;
      this.IsGenerationREMIT = true;
    }
    else if (this.outage.code == AvailabilityOutageCodes.LE) {
      this.outage.availabilityValue = null;
      this.IsConsumptionREMIT = false;
      this.IsGenerationREMIT = true;
    }
    else if (this.outage.code == AvailabilityOutageCodes.GE) {
      this.outage.availabilityValue = null;
      this.IsConsumptionREMIT = true;
      this.IsGenerationREMIT = false;
    }
  }

  public IsMissingData(): boolean {
    let isMissingData: boolean = false;
    if (this.outage.availabilityType == null
      || this.outage.bmu == null
      || this.outage.code == null
      || this.outage.availabilityValue == null
      || this.IsValidDate((this.outage.fromDate ?? "").toString().trim()) == false
      || this.IsValidDate((this.outage.toDate ?? "").toString().trim()) == false
      || this.outage.reasonSummary == null
      || (this.IsFHC && (this.outage.internalNotes ?? "").toString().trim() == "")
      || this.outage.notifiedBy == null
      || this.outage.notificationMethod == null
      || this.IsValidDate((this.outage.notificationDateTime ?? "").toString().trim()) == false)
      isMissingData = true;

    return isMissingData;
  }

  private IsValidDate(date: string): boolean {
    let d;
    try {
      d = new Date(date);
    }
    catch { }
    return d instanceof Date && !isNaN(d.getDate());
  }


  public ValidateVolume() {
    if (this.ActiveBMU) {
      let validatedVolume: number | null = this.outage.availabilityValue ?? 0;
      console.log(this.outage.code);
      console.log(validatedVolume);
      console.log(this.ActiveBMU.generationCapacity);
      if (this.outage.code == AvailabilityOutageCodes.GE && validatedVolume != 0 && validatedVolume != this.ActiveBMU.generationCapacity) {
        validatedVolume = null;

        this.alertService.ShowAlert(new AlertMessage(AlertLevel.Information, "The registered consumption capacity is " + this.ActiveBMU.generationCapacity + "MW. Consumption REMITS for " + this.outage.bmu.substring(0, 4) + " can only have a capacity of 0 or " + this.ActiveBMU.generationCapacity + "MW."));
      }
      else {
        if (validatedVolume < 0)
          validatedVolume = 0;
        if (validatedVolume > this.ActiveBMU.generationCapacity) {
          validatedVolume = this.ActiveBMU.generationCapacity
        }
        if (this.ActiveBMU.allowDecimalsInRemit == true) {
          validatedVolume = Math.trunc(validatedVolume * 1000) / 1000;
        }
        if (this.ActiveBMU.allowDecimalsInRemit == false) {
          validatedVolume = Math.trunc(validatedVolume);
        }
      }
      this.outage.availabilityValue = validatedVolume;
    }
  }
}
