import { Component, ComponentRef, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastComponent, ToastService } from '@engie-group/fluid-design-system-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { AlertMessage } from 'src/app/core/Dtos/alert.entity';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { ToastViewerComponent } from '../toast/toast.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public DisplayLoading: boolean = false;
  public ExtraInfoHeader: string = "";
  public get UserName(): string {
    return this._authStateService.userName;
  }
 
  constructor(private _authStateService: AuthStateService, 
    private _alertService: AlertsService,
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef)
  {
    this.ExtraInfoHeader = environment.extraInfoHeader ?? "";
    _alertService.onAPICallCountChange.subscribe(m => {
      console.log("API Calls count: " + m);
      this.DisplayLoading = m != 0;
    });

    _alertService.onAlertReceived.subscribe((message: AlertMessage) => {
      const toastRef: ComponentRef<ToastViewerComponent> = this.toastService.open(ToastViewerComponent, {viewContainerRef: this.viewContainerRef});
      toastRef.instance.Title= message.Type.toString();
      toastRef.instance.Message= message.Message;
    });
  }

  LogOff() {
    this._authStateService.logout();
  }
}
