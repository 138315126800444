import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthStateService } from "./core/services/auth-state.service";

@Injectable()
export class AuthGuard {
    // #region Constructors (1)
    private _globalState: AuthStateService;
    constructor(globalState: AuthStateService) {
        this._globalState = globalState;
    }

    // #endregion Constructors (1)

    // #region Public Methods (1)

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._globalState.isAuthenticated) {
            return true;
        }
        const navstate = state.url.includes('/callback/') ? state.url : '';

        if (state.url.indexOf('?') === -1) {
            this._globalState.initFlow('');
            this._globalState.runLoginFlow();
            return state.url.startsWith('/home');
        } else {
            this._globalState.runLoginFlow();
            return true;
        }
    }

    // #endregion Public Methods (1)
}


