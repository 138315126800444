import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailabilityOutageFullMinDTO, AvailabilityOutage, AvailabilityOutageMinDTO } from 'src/app/core/Dtos/Remit';
import { RemitRevisionsData } from 'src/app/core/Dtos/Remit-revisions.entity';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { RemitsService } from 'src/app/core/services/remits.service';
import { BaseComponent } from 'src/app/features/base-component'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outages-revisions',
  templateUrl: './outages-revisions.component.html',
  styleUrls: ['./outages-revisions.component.scss']
})
export class OutagesRevisionsComponent extends BaseComponent {
  public outageId: number;
  public CanCreateRevision: boolean = false;
  public FailedToSubmitREMIT: boolean = false;
  public IsRemitReporter: boolean = false;
  public currentRemitRevisions: AvailabilityOutageFullMinDTO[] = [];

  constructor(
    private alertService: AlertsService,
    private remitsService: RemitsService, 
    private globalState: AuthStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    public DateFormatHelper: DateFormatHelperService) {
    
    super(alertService, DateFormatHelper);
    this.IsRemitReporter = globalState.groups.indexOf(environment.remitReporterGroup) != -1;
    this.outageId = this.activatedRoute.snapshot.params['outageId'];
    this.GetRemitDetails();
  }

  public GetRemitDetails() {
    this.remitsService.GetOutage(this.outageId).subscribe(data => {
      if (data.remitTypesGenerated != -1 && data.remitTypeToGenerate != data.remitTypesGenerated)
        this.FailedToSubmitREMIT = true;
      else if (new Date(data.toDate) > new Date())
        this.CanCreateRevision = true;
    });

    this.remitsService.GetOutageRevisions(this.outageId).subscribe(
      resp =>
        this.currentRemitRevisions = resp
    )
  }

  public BackToRemits() {
    localStorage.setItem("loadPrevResults", "Y");
    this.router.navigate(['/']);
  }

  public CreateNewRevision() {
    this.router.navigate(['outages-new', this.outageId]);
  }

  public ExportToExcel()
  {
    this.ExportDataToCSV(this.currentRemitRevisions, 'outage-revisions.csv');
  }

  public SortData(sortColumn: string) {
    console.log("Not Implemented");
  }
}
