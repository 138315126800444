import { Injectable } from '@angular/core';
import { BaseHttpApiService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filters_GetAvailability } from '../Dtos/Filters.entity';
import { AvailabilityOutageFullMinDTO, AvailabilityOutageMinDTO, AvailabilityOutage } from '../Dtos/Remit';
import { RemitRevisionsData } from '../Dtos/Remit-revisions.entity';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class RemitsService extends BaseHttpApiService {

  constructor(private http : HttpClient) { 
    super();
  }

  public GetAvailability(filters: Filters_GetAvailability) : Observable<AvailabilityOutageMinDTO[]> {
    let queryParams: string = "";
    if (filters.outageActiveOn != null)
      queryParams = "activeOnDate=" + filters.outageActiveOn.toISOString();

    for (let i = 0; i < filters.bmUs.length; i++)
      queryParams += "&bmus=" + filters.bmUs[i];

    for (let i = 0; i < filters.availabilityTypes.length; i++)
      queryParams += "&availabilityTypes=" + filters.availabilityTypes[i];
    
    return this.http.get<AvailabilityOutageMinDTO[]>(this.apiURL + "Remits?" + queryParams);
  }

  public GetOutageRevisions(remitId: number): Observable<AvailabilityOutageFullMinDTO[]> {
    return this.http.get<AvailabilityOutageFullMinDTO[]>(this.apiURL + "Remits/Full/" + remitId);
  }

  public GetOutage(outageId : number): Observable<AvailabilityOutage>
  {
    return this.http.get<AvailabilityOutage>(this.apiURL + "Remits/" + outageId);
  }

  public ValidateOverlaps(outage: AvailabilityOutage):Observable<AvailabilityOutageMinDTO[]>
  {
    return this.http.get<AvailabilityOutageMinDTO[]>(this.apiURL + "Remits/ValidateOverlaps?bmu=" + outage.bmu + "&localDateTimeFrom=" + outage.fromDate + "&localDateTimeTo=" + outage.toDate + (outage.id != 0 ? "&existingAvailabilityId=" + outage.id : ""))
  }

  public CreateOutage(outage: AvailabilityOutage)
  {
    return this.http.post(this.apiURL + "Remits", outage);
  }

  public CancelOutage(outageId: number, outage: AvailabilityOutage)
  {
    return this.http.delete(this.apiURL + "Remits/Cancel/" + outageId, {body: outage});
  }

  public ReSubmitRemit(outageId: number){
    return this.http.post(this.apiURL + "Remits/ReSubmitREMIT/?availabilityId=" + outageId, {});
  }
}
