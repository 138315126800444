import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormItemComponent, ButtonComponent, FormFieldDirective,
  ToastComponent, SelectComponent, SpinnerComponent, CheckboxComponent, PaginationComponent, RadioComponent, RadioGroupComponent, 
  ToastService } from '@engie-group/fluid-design-system-angular';
import { HeaderComponent } from './features/shared/header/header.component';
import { OutagesNewComponent } from './features/outages/outages-new/outages-new.component';
import { OutagesRevisionsComponent } from './features/outages/outages-revisions/outages-revisions.component';
import { OutagesListComponent } from './features/outages/outages-list/outages-list.component';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { AuthStateService, IAM_CONFIG } from './core/services/auth-state.service';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth-guard';
import { CallbackComponent } from './login/callback/callback.component';
import { LogoutComponent } from './login/logout/logout.component';
import { OutageOverlapComponent } from './features/outages/outage-overlap/outage-overlap.component';
import { APIInterceptor } from './core/api.interceptor';
import { ToastViewerComponent } from './features/shared/toast/toast.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OutageCancellationComponent } from './features/outages/outage-cancellation/outage-cancellation.component';

@NgModule({
  declarations: [
    OutagesListComponent,
    OutagesRevisionsComponent,
    OutagesNewComponent,
    AppComponent,
    HeaderComponent,
    CallbackComponent,
    LogoutComponent,
    OutageOverlapComponent,
    ToastViewerComponent,
    OutageCancellationComponent
  ],
  imports: [
    FormItemComponent, FormFieldDirective, ButtonComponent, ToastComponent, SelectComponent, SpinnerComponent, CheckboxComponent, PaginationComponent, RadioComponent, RadioGroupComponent, 
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.apiUrl
        ],
        sendAccessToken: true,
      }})
  ],
  providers: [
    ToastService,
    AuthGuard,
    {
      provide: IAM_CONFIG,  // That's the token we defined previously
      useValue: environment.iam,  // That's the actual service itself
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
