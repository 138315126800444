import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard';
import { OutagesListComponent } from './features/outages/outages-list/outages-list.component';
import { OutagesNewComponent } from './features/outages/outages-new/outages-new.component';
import { OutagesRevisionsComponent } from './features/outages/outages-revisions/outages-revisions.component';
import { CallbackComponent } from './login/callback/callback.component';
import { LogoutComponent } from './login/logout/logout.component';

const routes: Routes = [
  {path: '', component: OutagesListComponent, canActivate: [AuthGuard]},
  {path: 'callback', component: CallbackComponent, canActivate: [AuthGuard]},
  {path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
  {path: 'revisions/:outageId', component: OutagesRevisionsComponent, canActivate: [AuthGuard]},
  {path: 'outages-new/:outageId', component: OutagesNewComponent, canActivate: [AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

