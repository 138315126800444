export const environment = {
    iam: {
        clientId: '3-b-pad-portal-uat-UC0G8XRECGNPGVL',
        issuer: 'https://gem.okta-emea.com/oauth2/aus2jp8vmz3yYrDnc0i7',

        redirectUri: 'https://pad.noprod.gems.engie.co.uk/callback',
        logoutUrl: 'https://pad.noprod.gems.engie.co.uk/logout',
        postLogoutRedirectUri: 'https://pad.noprod.gems.engie.co.uk/logout',
        responseType: 'code',
        // tslint:disable-next-line: max-line-length
        scope: 'openid profile email api.pad-portal',
        showDebugInformation: true,
        sessionChecksEnabled: true,
        nonceStateSeparator: ';',
        useSilentRefresh: true,
        fallbackAccessTokenExpirationTimeInSec: 3600,
        silentRefreshRedirectUri: 'https://pad.noprod.gems.engie.co.uk/assets/silent-refresh.html',
        tokenEndpoint: 'https://gem.okta-emea.com/oauth2/aus2jp8vmz3yYrDnc0i7/v1/token'
    },
    cspHeader: "default-src 'self' 'nonce-@NONCE@' @API_URL@ @OKTA_URL@/; style-src 'self' https://fonts.googleapis.com 'nonce-@NONCE@'; font-src 'self' https://fonts.gstatic.com; script-src 'self' https://fonts.googleapis.com",
    nonce: "fpG9asf28JXxXVb3TfU",
    production: false,
    enableConsumptionREMIT: true,
    extraInfoHeader: "SANDBOX - THIS IS NOT PROD",
    apiUrl: 'https://pad.api.noprod.gems.engie.co.uk',
    tritonUsersGroup: 'uat-pad-triton-users',
    remitResubmitGroup: 'uat-api-pad-remit-resubmit',
    remitReporterGroup: 'uat-api.pad-remit-reporter'

//    apiUrl: 'https://ng6l2r7kdg.execute-api.eu-west-2.amazonaws.com/beta/'
}
/*
export const environment = {
    iam: {
        clientId: '2-b-PAD-Portal-YD182A40JBJ3E4R',
        issuer: 'https://gem-beta.oktapreview.com/oauth2/aus3v6rg3e9zNHMxe0x6',

        redirectUri: 'https://wa-xvdadev-01.europepower.net/remits/callback',
        logoutUrl: 'https://wa-xvdadev-01.europepower.net/remits/logout',
        postLogoutRedirectUri: 'http://wa-xvdadev-01.europepower.net/remits/logout',
        responseType: 'code',
        // tslint:disable-next-line: max-line-length
        scope: 'openid profile email api.pad-portal',
        showDebugInformation: true,
        sessionChecksEnabled: true,
        nonceStateSeparator: ';',
        useSilentRefresh: true,
        fallbackAccessTokenExpirationTimeInSec: 3600,
        silentRefreshRedirectUri: 'https://wa-xvdadev-01.europepower.net/remits/assets/silent-refresh.html',
        tokenEndpoint: 'https://gem-beta.oktapreview.com/oauth2/aus3v6rg3e9zNHMxe0x6/v1/token'
    },
    production: false,
    enableConsumptionREMIT: true,
    apiUrl: 'https://wa-xvdadev-01.europepower.net/remits-api/'
}
*/
